///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Imports
import { useState, useEffect } from "react";
import "../styles/components.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import { useAuth } from "../contexts/AuthContext";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining a component to display all guests of an event
function DisplayEvents({ event }) {

  //Defining a state variable storing all guests in an array 
  const [eventGuests, setEventGuests] = useState([]);
  const [authenticatedViewer, setAuthenticatedViewer] = useState("")
  const {currentUser} = useAuth()

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  //Fetching the guest data of each guest as soon as the component mounts
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_API_URL}/authenticatedViewer`, {
      method: "POST",
      body: JSON.stringify({ eventName: event }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then((data) => setAuthenticatedViewer(data))
      .catch((e) => console.error("Error when fetching Guest Data: ", e)); 
  
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    fetch(`${process.env.REACT_APP_SERVER_API_URL}/getGuestData`, {
      method: "POST",
      body: JSON.stringify({ eventName: event }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then((data) => setEventGuests(data))
      .catch((e) => console.error("Error when fetching Guest Data: ", e)); 
  }, [event]);

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Checks if the user is authenticated to view the guestlist of his event. If not, the list will not be display
//The authentication check has to be made outside the useEffect(). If it was made inside before the guestData fetch request, then you could only return to the outside of the UseEffect Hook and not the outside this component
//Hence the component with its guests would be still rendered, even if the user is not authenticated to view them 
if(currentUser.email !== authenticatedViewer.authenticatedViewer){
  console.log("You have no permissions to view this Event!")
  return
} 
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Creating a table which lists all the users
  return (
    <>
      {eventGuests.map((guest, index) => (
        <tbody className="render-guests">
          <tr>
            <td>{index}</td>
            <td>{guest.split(" ")[0]}</td>
            {/* Removing the whitespace from the string, by splitting it into two arrays, whenever a whitespace is encountered => The first element of the array contains
                the first name and the 2nd element the last name*/}
            <td>{guest.split(" ")[1]}</td>
          </tr>
        </tbody>
      ))}
    </>
  );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Creating a Navbar to show each event
function NavBarItems({ eventSessions, onItemSelected, eventGuests }) {
  console.log(eventSessions, "\n", eventGuests);
  return (
    <Tabs
      defaultActiveKey="profile"
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      {eventSessions &&
        eventSessions.map((event, index) => (
          <Tab
            key={index} // Ensure each tab has a unique key
            onClick={(onItemSelected = event)}
            eventKey={index.toString()} // Use a unique identifier for each tab
            title={event}
          >
            <Table striped bordered hover>
              {onItemSelected ? (
                <DisplayEvents event={event} />
              ) : (
                <>
                  <h1>No Events!</h1>
                </>
              )}
            </Table>
          </Tab>
        ))}
    </Tabs>
  );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining the Guestlist component which puts together all other components and displays the complete guestlist
function Guestlist() {

  //Defining state variables storing the event sessions in an array
  const [eventSessions, setEventSessions] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  //Fetching the event sessions on every component mount
  useEffect(() => {
    fetch(`http://localhost:8080/getEventSessions`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((data) => setEventSessions(data))
      .catch((e) =>
        console.error(
          "Error occured when fetching Event Sessions from the Server: ",
          e
        )
      );
  }, []);

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Setting the selected event in order to show the right event guests
  const handleItemSelected = (selectedEvent) => {
    setSelectedEvent(selectedEvent);
  };

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Returning the mark up to the component
  return (
    <>
      <ul className="navbar-nav">
        <NavBarItems
          key={1}
          eventSessions={eventSessions}
          onItemSelected={handleItemSelected}
        />
      </ul>
      <main></main>
    </>
  );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//exports
export default Guestlist;
