////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Imports
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import "../styles/logreg.css";


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining the Register component
function Register() {

  //Defining the state variables and initializing the firebase authentication
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { signUp, currentUser } = useAuth();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Only allowing new registrations by certain verified users.
//This is because verified users have access to the guestlist => If everybody could create a verified account, then everybody would have have access to the guestlist resulting in privacy issues
useEffect(() => { //useEffect Hook in order to only access the button DOM element after it has been rendered || Otherwise it would try to access the element right away, before the DOM is rendered completely, resulting in an error since the element is non-existent
  const registerButton = document.getElementById("registerButton")
  currentUser ? registerButton.disabled = false : registerButton.disabled = true
}, [])

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//Handling the user registration and adding their credentials to the firebase authentication
  async function handleSubmit(e) {
    e.preventDefault();
    if (!error){
    try {
      setLoading(true);
      await signUp(email, password);
    } catch {
      setError("Failed to create Account!");
    }
    setLoading(false);
    window.document.location = "/";
  }
  else{
    throw new Error("Passwords are either not meeting the requirements or they are not matching!")
}}

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining a ErrorMessage component, which checks if the password meets the requirements (password and confirmPassword are equal, they have at least 8 characters)
  function ErrorMessage() {
    if (
      password !== confirmPassword &&
      password.length >= 8 &&
      confirmPassword.length >= 8
    ) {
      setError(true);
      return <p style={{ marginLeft: "0.7rem" }}>Passwords do not match</p>;
    } else {
      if (password.length < 8 && confirmPassword.length < 8 && password.length > 0 ) {
        setError(true);
        return (
          <p style={{ marginLeft: "0.7rem" }}>
            Passwörter müssen mindestens 8 Zeichen lang sein!
          </p>
        );
      }
      else{
        setError(false)
      }
    }
  }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


//Returning the mark up to the component
return (
    <div className="login-container">
      <form
        style={{ backgroundColor: "#ffffff" }}
        onSubmit={handleSubmit}
        class="form"
      >
        <p class="form-title">Zurzeit können nur berechtigte Nutzer einen Account erstellen.</p>
        <div class="input-container">
          <input
            required
            id="emailBox"
            type="text"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <input
            required
            id="passwordBox"
            type="password"
            placeholder="Passwort"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <span>
            <svg
              stroke="currentColor"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                stroke-width="2"
                stroke-linejoin="round"
                stroke-linecap="round"
              ></path>
            </svg>
          </span>
        </div>
        <div class="input-container">
          <input
            required
            id="passwordConfirmBox"
            type="password"
            placeholder="Passwort bestätigen"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
          />

          <span>
            <svg
              stroke="currentColor"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                stroke-width="2"
                stroke-linejoin="round"
                stroke-linecap="round"
              ></path>
              <path
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                stroke-width="2"
                stroke-linejoin="round"
                stroke-linecap="round"
              ></path>
            </svg>
          </span>
          {/* {error ? <ErrorMessage /> : null} */}
          <ErrorMessage />
        </div>
        <button id="registerButton" disabled={true} class="submit" type="submit">
          Registrieren
        </button>

        <p class="signup-link">
          Bereits ein Account?
          <a href="/login"> Login</a>
        </p>
      </form>
    </div>
  );
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Exports
export default Register;
