////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Imports
import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import "../styles/checkout.css";
import QRCode from "qrcode";
import { useID } from "../contexts/TicketInformationProvider";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining the SuccessedPayment Component
function SuccessedPayment() {
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Checking if the user completed the payment by checking if there is a valid successID
  const { successID } = useParams()
  const isSuccessIDValid = () => {
    const digits = successID.split('').map(Number); // Convert string to array of numbers
    const sum = digits.reduce((acc, curr) => acc + curr, 0); // Calculate sum of digits
    return sum === 50;
  }
  if (!isSuccessIDValid()){
    throw new Error("You did not pay! Please buy a ticket")
  }
  //Initializing the ticket information context and the updateTicketInformation procedure || Defining a Reference to hold the source of the QR-Code
  const { ticketInformation, updateTicketInformation } = useID();

  const srcRef = useRef("");

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Updating the ticket information context and setting the payment to true, as soon as the component mounts 
  useEffect(() => {
    const updateEventContext = async () => {
      await updateTicketInformation({
        payment: "true",
      });
    };
    updateEventContext();

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Creating the QR-Code with the given URL Parameters 
    const urlParams = new URLSearchParams(ticketInformation);
    const guestObjectQuery = urlParams.toString();

    const qrCodeURL = `https://www.party-tickets.com/checkinguest?${guestObjectQuery}`; //replace server url with actual domain
    console.log(qrCodeURL);
    QRCode.toDataURL(qrCodeURL, (err, res) => {
      if (err) console.error(err);
      srcRef.current = res;
    });

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Sending the confirmation email, together with the QR Code and the bill (Bill is not seen here, but in the server side code), if the email is not yet sent
    if (!ticketInformation.hasSentEmail && isSuccessIDValid()) {
      const sendEmail = async () =>
        await fetch(`${process.env.REACT_APP_SERVER_API_URL}/sendEmail`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: ticketInformation.emailAddress,
            QRCode: srcRef.current,
            requestUrl: "/successedpayment",
          }),
        })
          .then((res) => console.log(res))
          .then(
            await updateTicketInformation({
              hasSentEmail: true,
            })
          )
          .catch((e) => console.log("Could not send Email to the Guest!: ", e));
      sendEmail();
    }
  }, []);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Returning the mark up to the component and showing the QR Code together with the transaction information
  return (
    <>
      <div style={{ height: "800px" }} className="form-container">
        <h1 className="stripe-session-header">Dein Ticket!</h1>

        <hr className="trim-bill" />
        <div>
          <div style={{ marginBottom: "4.5rem" }} className="QRCode">
            <img
              src={srcRef.current}
              alt="Hier sollte dein QRCode sein, falls nicht, wende dich an den Support."
            />
          </div>
          <hr className="trim-bill" />
          <p>
            Das Ticket und die Rechnung findest du außerdem in deinen Emails
          </p>
          <br />
          <div className="payment-paragraph">
            <h3 style={{ textAlign: "center" }}>
              {" "}
              {ticketInformation["priceInCents"] / 100}€ bezahlt!{" "}
              <p style={{ color: "green" }}>&#10003;</p>{" "}
            </h3>
            <button
              style={{ marginRight: "1.5rem" }}
              className="BuyTicketButton"
              onClick={() => (window.location.href = "/")}
            >
              Home
            </button>
            <button
              onClick={() => (window.location.href = "/guestlist")}
              className="BuyTicketButton"
            >
              Event-Sessions
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Exports
export default SuccessedPayment;
