///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Imports
import { useState } from "react";
import "../styles/logreg.css";
import { useAuth } from "../contexts/AuthContext";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining the Login Component 
function Login() {

  //Defining states holding important values || Initializing the firebase login authentication
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, currentUser } = useAuth();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  //Handling the login event => Users logs into account with the correct credentials which are checked by firebase authentication
  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setLoading(true);
      await login(email, password);
      setError(false);
      window.document.location = "/";
    } catch {
      setError(true);
    }
    setLoading(false);
  }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining an ErrorMessage component
  function ErrorMessage() {
    if (error === true) {
      return <p className="error-message">Failed to Login!</p>;
    }
  }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Returning the mark up to the component  
  return (
    <div className="login-container">
      <form
        style={{ backgroundColor: "#ffffff" }}
        onSubmit={handleSubmit}
        class="form"
      >
        <p class="form-title">Willkommen zurück</p>
        <div class="input-container">
          <input
            required
            onChange={(event) => setEmail(event.target.value)}
            placeholder="Enter email"
            type="email"
          />
          <span>
            <svg
              stroke="currentColor"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                stroke-width="2"
                stroke-linejoin="round"
                stroke-linecap="round"
              ></path>
            </svg>
          </span>
        </div>
        <div class="input-container">
          <input
            onChange={(event) => setPassword(event.target.value)}
            placeholder="Enter password"
            type="password"
          />
          <span>
            <svg
              stroke="currentColor"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                stroke-width="2"
                stroke-linejoin="round"
                stroke-linecap="round"
              ></path>
              <path
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                stroke-width="2"
                stroke-linejoin="round"
                stroke-linecap="round"
              ></path>
            </svg>
          </span>
          <ErrorMessage />
        </div>
        <button class="submit" type="submit">
          Login
        </button>
        <p class="signup-link">
          Noch kein Account?
          <a href="/register"> Registrieren</a>
        </p>
      </form>
    </div>
  );
}

export default Login;
